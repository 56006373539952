<template>
  <div class="main-content">
    <b-overlay
      :show="showOverlay"
      opacity="0.60"
      rounded="sm"
      variant="white"
      no-wrap
    >
    </b-overlay>
    <h2 class="mb-4">Vendors</h2>
    <!-- <div class="wrapper"> -->
    <vue-good-table
      :columns="columns"
      :search-options="{
        enabled: true,
        placeholder: 'Search this table',
      }"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        dropdownAllowAll: false,
      }"
      styleClass="tableOne vgt-table"
      :selectOptions="{
        enabled: false,
        selectionInfoClass: 'table-alert__box',
      }"
      :rows="vendors"
      :totalRows="vendorPaginationOpts.totalCount"
      @on-per-page-change="onPerPageChange"
      @on-page-change="onPageChange"
    >
      <div slot="table-actions" class="mb-3">
        <b-button variant="primary" class="btn-rounded" @click="addVendor()">
          Add Vendor
        </b-button>
      </div>

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'button'">
          <a href="">
            <i class="i-Eraser-2 text-25 text-success mr-2"></i>
            {{ props.row.button }}</a
          >
          <a href="">
            <i class="i-Close-Window text-25 text-danger"></i>
            {{ props.row.button }}</a
          >
        </span>

        <span v-else-if="props.column.field == 'action'">
          <b-dropdown
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item @click="navigateToEdit(props.row)">Edit</b-dropdown-item>
            <b-dropdown-item @click="openDeleteConfirm(props.row)"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </span>
      </template>
    </vue-good-table>

    <b-modal
      id="vendor-delete-modal"
      size="sm"
      centered
      title="Delete"
      hide-footer
    >
      <b-form>
        <p>Are you sure you want to delete?</p> 
        <div class="d-flex justify-content-end">
          <b-button
            @click.prevent="$bvModal.hide('vendor-delete-modal');"
            type="button"
            >Cancel</b-button
          >
          <b-button
            @click.prevent="actionDeleteVendor()"
            type="button"
            variant="primary"
            class="ml-2"
            >Yes</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Vendor",
  },
  data() {
    return {
      showOverlay: false,
      columns: [
        {
          label: "Name",
          field: "supplierName",
        },
        {
          label: "Email",
          field: "email",
          html: true,
        },
        {
          label: "Mobile",
          field: "phoneNo",
        },
        {
          label: "Country",
          field: "country",
        },
        {
          label: "Website",
          field: "website",
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      pageSize: 0,
      selectedRow: ""
    };
  },
  mounted() {
    this.getAllVendors();
  },
  computed: {
    ...mapGetters(["vendors", "vendorPaginationOpts"]),
  },
  methods: {
    ...mapActions(["getVendors", "deleteVendor"]),
    getAllVendors() {
      this.showOverlay = true;
      this.getVendors().then((c) => {
        this.showOverlay = false;
      });
    },
    addVendor() {
      this.$router.push("/addvendor");
    },
    onPageChange(params) {
      this.showOverlay = true;
      let skip;
      if (params.currentPage > params.prevPage) {
        skip = params.currentPerPage * params.prevPage;
      } else {
        skip = params.currentPerPage / params.prevPage;
      }
      this.getVendors(`pageSize=${params.currentPerPage}&skip=${skip}`)
        .then((c) => {
          this.showOverlay = false;
        })
        .catch((err) => {
          this.showOverlay = false;
        });
    },
    onPerPageChange(params) {
      this.showOverlay = true;
      this.pageSize = params.currentPerPage;
      this.getVendors(`pageSize=${this.pageSize}`)
        .then((c) => {
          this.showOverlay = false;
        })
        .catch((err) => {
          this.showOverlay = false;
        });
    },
    navigateToEdit(colData) {
      this.$router.push('/editvendor/'+colData.id)
    },
    openDeleteConfirm(colData) {
      this.selectedRow = colData;
      this.$bvModal.show('vendor-delete-modal');
    },
    actionDeleteVendor() {
      this.deleteVendor(this.selectedRow.id)
      .then((c) => {
          this.$bvModal.hide('vendor-delete-modal');
          this.getAllVendors();
          this.$bvToast.toast(c, {
            title: "Success",
            variant: "success",
            autoHideDelay: 5000,
          })
        })
        .catch((err) => {
          this.$bvModal.hide('vendor-delete-modal');
          this.$bvToast.toast(err, {
            title: "Error",
            variant: "danger",
            autoHideDelay: 5000,
          })
        });
    }
  },
  
};
</script>
<style>
.vgt-inner-wrap {
  padding: 20px !important;
}
</style>

